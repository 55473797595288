import axios from 'axios';
import { URL_API } from './UrlApi';

export const enviarFormulario = async (dadosFormulario) => {
    return await axios.post(`${URL_API}/forms`, dadosFormulario)
        .then(response => {
            console.log('Dados enviados com sucesso!', response.data);
            return response.data; // Retorna os dados recebidos na resposta
        })
        .catch(error => {
            console.error('Erro ao enviar os dados:', error);
            throw error; // Lança o erro para ser tratado em outro lugar, se necessário
        });
};

export const resultadoById = async (id) => {
    return await axios.get(`${URL_API}/forms-result/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Receber dados do resultado:', error);
            throw error;
        });
};

export const getAllAplicadores = async () => {
    return await axios.get(`${URL_API}/aplicador-form`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Receber dados dos aplicadores:', error);
            throw error;
        });
};

export const getAllAplicadoresById = async (id_aplicador) => {
    return await axios.get(`${URL_API}/aplicador-form/${id_aplicador}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Receber dados dos aplicadores:', error);
            throw error;
        });
};

export const viewEmail = async (result_id) => {
    return await axios.patch(`${URL_API}/paciente/view-email/${result_id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Receber dados dos aplicadores:', error);
            throw error;
        });
}

export const findByDoc = async (doc) => {
    return await axios.get(`${URL_API}/paciente/find-by-doc/${doc}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Erro ao receber informaçoes find-by-doc:', error);
            throw error;
        });
}
