import axios from 'axios';

export const getEstados = async () => {
    return await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Receber Estados:', error);
        });
};

export const getCidadesByEstado = async (estado) => {
    return await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error('Receber Estados:', error);
        });
};
