import {
    AppBar, Box, ButtonBase, Card, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, MenuItem, Select, InputLabel, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Link
} from '@mui/material';
import { useEffect, useState } from 'react';
import InputMask from "react-input-mask";
import { enviarFormulario, getAllAplicadoresById, findByDoc } from '../../service/FormularioService';
import { useNavigate, useParams } from "react-router-dom";
import { getCidadesByEstado, getEstados } from '../../service/IbgeService';

function Sf36() {
    const navigate = useNavigate();

    //id vindo da URL
    const { id } = useParams();

    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [cpfValido, setCpfValido] = useState(true);
    const [dataNascimentoValida, setNascimentoValida] = useState(true);
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [nascimento, setNascimento] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    const [listaAplicador, setListaAplicador] = useState([]);
    const [listaEstados, setListaEstados] = useState([]);
    const [listaCidades, setListaCidade] = useState([]);


    const [fillTime, setFillTime] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [startTime, setStartTime] = useState(null);

    useEffect(() => {
        async function getAplicadores() {
            try {
                const retorno = await getAllAplicadoresById(id);
                if (retorno != null && retorno.length > 0) {
                    setListaAplicador(retorno);
                } else {
                    setOpenErrorAplicador(true)
                }
            } catch (error) {
                console.log(error);
            }
        }
        async function getEstadosIbge() {
            try {
                const retorno = await getEstados();
                if (retorno != null && retorno.length > 0) {
                    setListaEstados(retorno);
                }
            } catch (error) {
                console.log(error);
            }
        }

        getAplicadores()
        getEstadosIbge()
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const handleChangeNome = (event) => {
        //Iniciando o contador do formulario
        if (!formSubmitted && startTime === null) {
            setStartTime(Date.now());
        }
        setNome(event.target.value);
    };

    async function fetchCpf(meuCpf) {
        try {
            const retorno = await findByDoc(meuCpf);
            if (retorno != null && retorno != "") {
                const retornoCidade = await getCidadesByEstado(retorno.estado);
                setNome(retorno.nome)
                setTelefone(retorno.telefone)
                setEmail(retorno.email)
                const data = new Date(retorno.nascimento);
                const dataFormatada = data.toLocaleDateString('pt-BR');
                setNascimento(dataFormatada)
                setEstado(retorno.estado)
                setListaCidade(retornoCidade)
                setCidade(retorno.cidade)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeCpf = (event) => {
        const novoCpf = event.target.value;
        if (validarCPF(novoCpf)) {
            setCpfValido(true);
            //Se o aplicador for clinica relevium
            if (novoCpf.length === 14 && (id == 1 || id == 25)) {
                fetchCpf(novoCpf)
            }
        } else {
            setCpfValido(false);
        }
        // Atualize o estado do CPF
        setCpf(novoCpf);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangeTelefone = (event) => {
        setTelefone(event.target.value);
    };

    const handleChangeNascimento = (event) => {
        const nascimento = event.target.value;
        const [day, month, year] = nascimento.split('/');
        const date = new Date(year, month - 1, day);
        setNascimentoValida(true);
        const currentYear = new Date().getFullYear();
        if ((year > currentYear || year < currentYear - 100)
            || (isNaN(date.getTime()) || date >= new Date())
            || (isNaN(month) || month < 1 || month > 12)) {
            setNascimentoValida(false);
        }
        setNascimento(event.target.value);
    };

    const handleChangeEstado = (event) => {
        const estado = event.target.value
        async function listarCidades() {
            try {
                const retorno = await getCidadesByEstado(estado);
                if (retorno != null && retorno.length > 0) {
                    setListaCidade(retorno);
                }
            } catch (error) {
                console.log(error);
            }
        }
        setEstado(estado);
        listarCidades()
    };

    const handleChangeCidade = (event) => {
        setCidade(event.target.value);
    };

    const convertToISODate = (brDate) => {
        const [day, month, year] = brDate.split('/');
        const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return isoDate;
    }

    const [selectedRespostaUm, setSelectedRespostaUm] = useState(null);
    const [selectedRespostaDois, setSelectedRespostaDois] = useState(null);
    const [selectRespostaTresA, setSelectedRespostaTresA] = useState(null);
    const [selectRespostaTresB, setSelectedRespostaTresB] = useState(null);
    const [selectRespostaTresC, setSelectedRespostaTresC] = useState(null);
    const [selectRespostaTresD, setSelectedRespostaTresD] = useState(null);
    const [selectRespostaTresE, setSelectedRespostaTresE] = useState(null);
    const [selectRespostaTresF, setSelectedRespostaTresF] = useState(null);
    const [selectRespostaTresG, setSelectedRespostaTresG] = useState(null);
    const [selectRespostaTresH, setSelectedRespostaTresH] = useState(null);
    const [selectRespostaTresI, setSelectedRespostaTresI] = useState(null);
    const [selectRespostaTresJ, setSelectedRespostaTresJ] = useState(null);

    const [selectRespostaQuatroA, setSelectedRespostaQuatroA] = useState(null);
    const [selectRespostaQuatroB, setSelectedRespostaQuatroB] = useState(null);
    const [selectRespostaQuatroC, setSelectedRespostaQuatroC] = useState(null);
    const [selectRespostaQuatroD, setSelectedRespostaQuatroD] = useState(null);

    const [selectRespostaCincoA, setSelectedRespostaCincoA] = useState(null);
    const [selectRespostaCincoB, setSelectedRespostaCincoB] = useState(null);
    const [selectRespostaCincoC, setSelectedRespostaCincoC] = useState(null);

    const [selectRespostaSeis, setSelectedRespostaSeis] = useState(null);

    const [selectRespostaSete, setSelectedRespostaSete] = useState(null);
    const [selectRespostaOito, setSelectedRespostaOito] = useState(null);

    const [selectRespostaNoveA, setSelectedRespostaNoveA] = useState(null);
    const [selectRespostaNoveB, setSelectedRespostaNoveB] = useState(null);
    const [selectRespostaNoveC, setSelectedRespostaNoveC] = useState(null);
    const [selectRespostaNoveD, setSelectedRespostaNoveD] = useState(null);
    const [selectRespostaNoveE, setSelectedRespostaNoveE] = useState(null);
    const [selectRespostaNoveF, setSelectedRespostaNoveF] = useState(null);
    const [selectRespostaNoveG, setSelectedRespostaNoveG] = useState(null);
    const [selectRespostaNoveH, setSelectedRespostaNoveH] = useState(null);
    const [selectRespostaNoveI, setSelectedRespostaNoveI] = useState(null);

    const [selectRespostaDez, setSelectedRespostaDez] = useState(null);

    const [selectRespostaOnzeA, setSelectedRespostaOnzeA] = useState(null);
    const [selectRespostaOnzeB, setSelectedRespostaOnzeB] = useState(null);
    const [selectRespostaOnzeC, setSelectedRespostaOnzeC] = useState(null);
    const [selectRespostaOnzeD, setSelectedRespostaOnzeD] = useState(null);
    const [selectedNesletter, setSelectedNesletter] = useState(null)
    const [open, setOpen] = useState(false);
    const [openErrorAplicador, setOpenErrorAplicador] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);


    const handleRespostaUm = (event) => {
        setSelectedRespostaUm(event.target.value);
    }
    const handleRespostaDois = (event) => {
        setSelectedRespostaDois(event.target.value);
    }
    const handleRespostaTresA = (event) => {
        setSelectedRespostaTresA(event.target.value);
    }
    const handleRespostaTresB = (event) => {
        setSelectedRespostaTresB(event.target.value);
    }
    const handleRespostaTresC = (event) => {
        setSelectedRespostaTresC(event.target.value);
    }
    const handleRespostaTresD = (event) => {
        setSelectedRespostaTresD(event.target.value);
    }
    const handleRespostaTresE = (event) => {
        setSelectedRespostaTresE(event.target.value);
    }
    const handleRespostaTresF = (event) => {
        setSelectedRespostaTresF(event.target.value);
    }
    const handleRespostaTresG = (event) => {
        setSelectedRespostaTresG(event.target.value);
    }
    const handleRespostaTresH = (event) => {
        setSelectedRespostaTresH(event.target.value);
    }
    const handleRespostaTresI = (event) => {
        setSelectedRespostaTresI(event.target.value);
    }
    const handleRespostaTresJ = (event) => {
        setSelectedRespostaTresJ(event.target.value);
    }

    const handleRespostaQuatroA = (event) => {
        setSelectedRespostaQuatroA(event.target.value);
    }

    const handleRespostaQuatroB = (event) => {
        setSelectedRespostaQuatroB(event.target.value);
    }

    const handleRespostaQuatroC = (event) => {
        setSelectedRespostaQuatroC(event.target.value);
    }

    const handleRespostaQuatroD = (event) => {
        setSelectedRespostaQuatroD(event.target.value);
    }

    const handleRespostaCincoA = (event) => {
        setSelectedRespostaCincoA(event.target.value);
    }

    const handleRespostaCincoB = (event) => {
        setSelectedRespostaCincoB(event.target.value);
    }

    const handleRespostaCincoC = (event) => {
        setSelectedRespostaCincoC(event.target.value);
    }

    const handleRespostaSeis = (event) => {
        setSelectedRespostaSeis(event.target.value);
    }

    const handleRespostaSete = (event) => {
        setSelectedRespostaSete(event.target.value);
    }

    const handleRespostaOito = (event) => {
        setSelectedRespostaOito(event.target.value);
    }

    const handleRespostaNoveA = (event) => {
        setSelectedRespostaNoveA(event.target.value);
    }

    const handleRespostaNoveB = (event) => {
        setSelectedRespostaNoveB(event.target.value);
    }

    const handleRespostaNoveC = (event) => {
        setSelectedRespostaNoveC(event.target.value);
    }

    const handleRespostaNoveD = (event) => {
        setSelectedRespostaNoveD(event.target.value);
    }

    const handleRespostaNoveE = (event) => {
        setSelectedRespostaNoveE(event.target.value);
    }

    const handleRespostaNoveF = (event) => {
        setSelectedRespostaNoveF(event.target.value);
    }

    const handleRespostaNoveG = (event) => {
        setSelectedRespostaNoveG(event.target.value);
    }

    const handleRespostaNoveH = (event) => {
        setSelectedRespostaNoveH(event.target.value);
    }
    const handleRespostaNoveI = (event) => {
        setSelectedRespostaNoveI(event.target.value);
    }

    const handleRespostaDez = (event) => {
        setSelectedRespostaDez(event.target.value);
    }

    const handleRespostaOnzeA = (event) => {
        setSelectedRespostaOnzeA(event.target.value);
    }

    const handleRespostaOnzeB = (event) => {
        setSelectedRespostaOnzeB(event.target.value);
    }
    const handleRespostaOnzeC = (event) => {
        setSelectedRespostaOnzeC(event.target.value);
    }
    const handleRespostaOnzeD = (event) => {
        setSelectedRespostaOnzeD(event.target.value);
    }
    const handleSelectNewsletter = (event) => {
        setSelectedNesletter(event.target.value)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleErrorAplicadorClose = () => {
        setOpenErrorAplicador(false);
    };

    const [openConfirmation, setOpenConfirmation] = useState(true);
    const handleOpenTerms = () => {
        setOpenConfirmation(!openConfirmation);
    };

    const handleConfirmAction = () => {
        // Coloque o código para a ação de confirmação aqui
        setOpenConfirmation(false); // Feche o diálogo de confirmação após a ação
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.target.checkValidity() && cpfValido && dataNascimentoValida) {
            setIsLoading(true)
            //INICIO - Logica para contar tempo do formulario em minutos
            const endTime = Date.now();
            const tempoPreenchimento = (endTime - startTime) / 60000;
            setFillTime(tempoPreenchimento.toFixed(2));
            setFormSubmitted(true);
            //fim - Logica para contar tempo do formulario em minutos

            const dadosFormulario = {
                paciente: {
                    nome: nome,
                    cpf: cpf,
                    email: email,
                    telefone: telefone,
                    nascimento: convertToISODate(nascimento),
                    cidade: cidade,
                    estado: estado,
                    id_aplicador: id,
                    confirmou_email: 0,
                    receber_news: parseInt(selectedNesletter)
                },
                questao_1: parseInt(selectedRespostaUm),
                questao_2: parseInt(selectedRespostaDois),
                questao_3: [
                    parseInt(selectRespostaTresA),
                    parseInt(selectRespostaTresB),
                    parseInt(selectRespostaTresC),
                    parseInt(selectRespostaTresD),
                    parseInt(selectRespostaTresE),
                    parseInt(selectRespostaTresF),
                    parseInt(selectRespostaTresG),
                    parseInt(selectRespostaTresH),
                    parseInt(selectRespostaTresI),
                    parseInt(selectRespostaTresJ)
                ],
                questao_4: [
                    parseInt(selectRespostaQuatroA),
                    parseInt(selectRespostaQuatroB),
                    parseInt(selectRespostaQuatroC),
                    parseInt(selectRespostaQuatroD)
                ],
                questao_5: [
                    parseInt(selectRespostaCincoA),
                    parseInt(selectRespostaCincoB),
                    parseInt(selectRespostaCincoC)
                ],
                questao_6: parseInt(selectRespostaSeis),
                questao_7: parseInt(selectRespostaSete),
                questao_8: parseInt(selectRespostaOito),
                questao_9: {
                    a: parseInt(selectRespostaNoveA),
                    b: parseInt(selectRespostaNoveB),
                    c: parseInt(selectRespostaNoveC),
                    d: parseInt(selectRespostaNoveD),
                    e: parseInt(selectRespostaNoveE),
                    f: parseInt(selectRespostaNoveF),
                    g: parseInt(selectRespostaNoveG),
                    h: parseInt(selectRespostaNoveH),
                    i: parseInt(selectRespostaNoveI)
                },
                questao_10: parseInt(selectRespostaDez),
                questao_11: {
                    a: parseInt(selectRespostaOnzeA),
                    b: parseInt(selectRespostaOnzeB),
                    c: parseInt(selectRespostaOnzeC),
                    d: parseInt(selectRespostaOnzeD)
                },
                tempo_medio_preenchimento: tempoPreenchimento.toFixed(2)
            };
            // Lógica de envio do formulário
            console.log('Formulário válido. Dados enviados:');
            console.log(dadosFormulario)
            enviarFormulario(dadosFormulario)
                .then(retorno => {
                    setIsLoading(false)
                    console.log('Dados retornados:', retorno.uuid);
                    navigate("/sf36/result/" + retorno.uuid);
                    // Faça algo com os dados retornados
                })
                .catch(error => {
                    setIsLoading(false)
                    setOpen(true)
                    console.error('Erro na requisição:', error);
                });

        } else {
            console.log('Formulário inválido');
            setIsLoading(false)
        }
    }

    function validarCPF(cpf) {
        // Remove caracteres não numéricos do CPF
        cpf = cpf.replace(/[^\d]/g, '');

        if (cpf.length !== 11) {
            return false;
        }

        // Verifica se todos os dígitos são iguais (caso contrário, não é um CPF válido)
        if (/^(\d)\1+$/.test(cpf)) {
            return false;
        }

        // Calcula o primeiro dígito de verificação
        let soma = 0;
        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let resto = soma % 11;
        let digito1 = resto < 2 ? 0 : 11 - resto;

        // Calcula o segundo dígito de verificação
        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpf.charAt(i)) * (11 - i);
        }
        resto = soma % 11;
        let digito2 = resto < 2 ? 0 : 11 - resto;

        // Verifica se os dígitos de verificação são válidos
        if (parseInt(cpf.charAt(9)) === digito1 && parseInt(cpf.charAt(10)) === digito2) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <AppBar sx={{ backgroundColor: '#31515F', height: 200, position: 'static' }}>
                <Container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Box>
                        <img src="/logo.png" alt="Logo" style={{ height: 80 }} />
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Questionário Qualidade de Vida
                        </Typography>
                        <Typography variant="body1">
                            Preencha o questionário abaixo.
                        </Typography>
                    </Box>
                </Container>
            </AppBar>
            <Box sx={{
                mt: 2, display: 'flex', alignItems: 'center', height: '100%', display: 'flex', flexDirection: 'column'
            }}>
                {/* <iframe
                    className={{ aspectRation: 16 / 9 }}
                    src="https://www.youtube.com/embed/gkttxxDBBlA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe> */}
            </Box>
            <Container>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column' }}>
                        <Box>
                            <Typography sx={{ mb: 2 }}
                                variant="h6">
                                Aplicador: {listaAplicador[0] ? listaAplicador[0].nome : 'Nenhum aplicador selecionado.'}
                            </Typography>

                             <InputMask
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={handleChangeCpf}
                                disabled={false}
                                maskChar=" "
                                required
                            >
                                {() => <TextField
                                    style={{ width: "47%" }}
                                    sx={{ mb: 2, mr: 2 }}
                                    label="CPF"
                                    required
                                    variant="outlined"
                                    error={!cpfValido} // Adiciona a propriedade error com base na validade do CPF
                                    helperText={!cpfValido ? 'CPF inválido' : ''} // Exibe a mensagem de erro
                                />}
                            </InputMask>

                            <TextField
                                style={{ width: "100%" }}
                                label="Nome"
                                sx={{ mb: 2 }}
                                value={nome}
                                onChange={handleChangeNome}
                                required />

                            <TextField style={{ width: "48%" }}
                                label="E-mail"
                                type="email"
                                sx={{ mb: 2, mr: 2}}
                                required
                                value={email}
                                onChange={handleChangeEmail}
                            />
                            <InputMask
                                mask="(99) 99999-9999"
                                value={telefone}
                                onChange={handleChangeTelefone}
                                disabled={false}
                                maskChar=" "
                                required
                            >
                                {() => <TextField
                                    sx={{ minWidth: 180, mb: 2, mr: 2 }}
                                    label="Telefone"
                                    required
                                    variant="outlined" />}
                            </InputMask>

                            <InputMask
                                mask="99/99/9999"
                                value={nascimento}
                                onChange={handleChangeNascimento}
                                disabled={false}
                                maskChar=" "
                                required                            >
                                {() => <TextField
                                    sx={{ minWidth: 180, mb: 2, mr: 2 }}
                                    label="Data de nascimento"
                                    required
                                    variant="outlined"
                                    error={!dataNascimentoValida} // Adiciona a propriedade error com base no nascimento
                                    helperText={!dataNascimentoValida ? 'Data inválida' : ''} // Exibe a mensagem de erro
                                />}
                            </InputMask>
                            <FormControl>
                                <InputLabel id="estado-label">Selecione o estado</InputLabel>
                                <Select
                                    sx={{ minWidth: 180, mr: 2, mb: 2 }}
                                    labelId="estado-label"
                                    id="estado"
                                    value={estado}
                                    onChange={handleChangeEstado}
                                    label="Estado"
                                    required={true}
                                >
                                    {listaEstados.map((row) => (
                                        <MenuItem key={row.id} value={row.sigla}>
                                            {row.sigla}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="cidade-label">Selecione a cidade</InputLabel>
                                <Select
                                    labelId="cidade-label"
                                    id="cidade"
                                    value={cidade}
                                    sx={{ minWidth: 330, mb: 2 }}
                                    onChange={handleChangeCidade}
                                    label="Cidade"
                                    required={true}
                                >
                                    {listaCidades.map((row) => (
                                        <MenuItem key={row.id} value={row.nome}>
                                            {row.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>

                        {/* Pergunta 1 */}
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">
                                1) Em geral você diria que sua saúde é:
                            </Typography>
                            <RadioGroup
                                value={selectedRespostaUm}
                                onChange={handleRespostaUm}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Excelente
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - Muito boa
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Boa
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Ruim
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Muito ruim
                                    </>
                                } />
                            </RadioGroup>
                        </Box>
                        {/* Pergunta 2 */}
                        <Box sx={{ mt: 2, pointerEvents: selectedRespostaUm != null ? 'auto' : 'none', opacity: selectedRespostaUm != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                2) Comparada há um ano atrás, como você se classificaria sua idade em geral, agora?</Typography>
                            <RadioGroup
                                value={selectedRespostaDois}
                                onChange={handleRespostaDois}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Muito melhor
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - Um Pouco Melhor
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Quase a mesma
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Um pouco pior
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Muito pior
                                    </>
                                } />
                            </RadioGroup>
                        </Box>
                        {/* Pergunta 3 */}
                        <Box sx={{ mt: 2, pointerEvents: selectedRespostaDois != null ? 'auto' : 'none', opacity: selectedRespostaDois != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                3) Os seguintes itens são sobre atividades que você poderia fazer atualmente durante um dia comum. Devido à sua saúde, você teria dificuldade para fazer estas atividades? Neste caso, quando?
                            </Typography>
                            <Typography>
                                a) Atividades Rigorosas, que exigem muito esforço, tais como correr, levantar objetos pesados, participar em esportes árduos.
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresA}
                                onChange={handleRespostaTresA}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                b) Atividades moderadas, tais como mover uma mesa, passar aspirador de pó, jogar bola, varrer a casa.</Typography>
                            <RadioGroup
                                value={selectRespostaTresB}
                                onChange={handleRespostaTresB}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                c) Levantar ou carregar mantimentos
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresC}
                                onChange={handleRespostaTresC}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                d) Subir vários lances de escada
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresD}
                                onChange={handleRespostaTresD}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                e) Subir um lance de escada
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresE}
                                onChange={handleRespostaTresE}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                f) Curvar-se, ajoelhar-se ou dobrar-se
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresF}
                                onChange={handleRespostaTresF}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                g) Andar mais de 1 quilômetro
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresG}
                                onChange={handleRespostaTresG}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                h) Andar vários quarteirões
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresH}
                                onChange={handleRespostaTresH}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                i) Andar um quarteirão
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresI}
                                onChange={handleRespostaTresI}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                j) Tomar banho ou vestir-se
                            </Typography>
                            <RadioGroup
                                value={selectRespostaTresJ}
                                onChange={handleRespostaTresJ}

                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Sim, dificulta muito
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Sim, dificulta um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 3 - Não, não dificulta de modo algum
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 4 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaTresA != null ? 'auto' : 'none', opacity: selectRespostaTresA != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                4- Durante as últimas 4 semanas, você teve algum dos seguintes problemas com seu trabalho ou com alguma atividade regular, como conseqüência de sua saúde física?
                            </Typography>
                            <Typography>
                                a) Você diminui a quantidade de tempo que se dedicava ao seu trabalho ou a outras atividades?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaQuatroA}
                                onChange={handleRespostaQuatroA}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                b) Realizou menos tarefas do que você gostaria?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaQuatroB}
                                onChange={handleRespostaQuatroB}

                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                c) Esteve limitado no seu tipo de trabalho ou a outras atividades.
                            </Typography>
                            <RadioGroup
                                value={selectRespostaQuatroC}
                                onChange={handleRespostaQuatroC}

                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                d) Teve dificuldade de fazer seu trabalho ou outras atividades (p. ex. necessitou de um esforço extra).
                            </Typography>
                            <RadioGroup
                                value={selectRespostaQuatroD}
                                onChange={handleRespostaQuatroD}

                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 5 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaQuatroA != null ? 'auto' : 'none', opacity: selectRespostaQuatroA != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                5- Durante as últimas 4 semanas, você teve algum dos seguintes problemas com seu trabalho ou outra atividade regular diária, como conseqüência de algum problema emocional (como se sentir deprimido ou ansioso)?              </Typography>
                            <Typography>
                                a) Você diminui a quantidade de tempo que se dedicava ao seu trabalho ou a outras atividades?              </Typography>
                            <RadioGroup
                                value={selectRespostaCincoA}
                                onChange={handleRespostaCincoA}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                b) Realizou menos tarefas do que você gostaria?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaCincoB}
                                onChange={handleRespostaCincoB}

                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                c) Não realizou ou fez qualquer das atividades com tanto cuidado como geralmente faz.
                            </Typography>
                            <RadioGroup
                                value={selectRespostaCincoC}
                                onChange={handleRespostaCincoC}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 1 - Sim
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 2 - Não
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 6 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaCincoA != null ? 'auto' : 'none', opacity: selectRespostaCincoA != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                6- Durante as últimas 4 semanas, de que maneira sua saúde física ou problemas emocionais interferiram nas suas atividades sociais normais, em relação à família, amigos ou em grupo?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaSeis}
                                onChange={handleRespostaSeis}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - De forma nenhuma
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - Ligeiramente
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Moderadamente
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Bastante
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Extremamente
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 7 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaSeis != null ? 'auto' : 'none', opacity: selectRespostaSeis != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                7- Quanta dor no corpo você teve durante as últimas 4 semanas?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaSete}
                                onChange={handleRespostaSete}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Nenhuma
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - Muito leve
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Leve
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Moderada
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Grave
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 6 - Muito grave
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 8 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaSete != null ? 'auto' : 'none', opacity: selectRespostaSete != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                8- Durante as últimas 4 semanas, quanto a dor interferiu com seu trabalho normal (incluindo o trabalho dentro de casa)?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaOito}
                                onChange={handleRespostaOito}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - De maneira alguma
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - Um pouco
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Moderadamente
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Bastante
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Extremamente
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 9 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaOito != null ? 'auto' : 'none', opacity: selectRespostaOito != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                9- Estas questões são sobre como você se sente e como tudo tem acontecido com você durante as últimas 4 semanas. Para cada questão, por favor dê uma resposta que mais se aproxime de maneira como você se sente, em relação às últimas 4 semanas.
                            </Typography>
                            <Typography>
                                a) Quanto tempo você tem se sentindo cheio de vigor, de vontade, de força?              </Typography>
                            <RadioGroup
                                value={selectRespostaNoveA}
                                onChange={handleRespostaNoveA}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                b) Quanto tempo você tem se sentido uma pessoa muito nervosa?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveB}
                                onChange={handleRespostaNoveB}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                c) Quanto tempo você tem se sentido tão deprimido que nada pode anima-lo?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveC}
                                onChange={handleRespostaNoveC}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                d) Quanto tempo você tem se sentido calmo ou tranqüilo?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveD}
                                onChange={handleRespostaNoveD}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                e) Quanto tempo você tem se sentido com muita energia?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveE}
                                onChange={handleRespostaNoveE}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                f) Quanto tempo você tem se sentido desanimado ou abatido?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveF}
                                onChange={handleRespostaNoveF}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                g) Quanto tempo você tem se sentido esgotado?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveG}
                                onChange={handleRespostaNoveG}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                h) Quanto tempo você tem se sentido uma pessoa feliz?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveH}
                                onChange={handleRespostaNoveH}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                i) Quanto tempo você tem se sentido cansado?
                            </Typography>
                            <RadioGroup
                                value={selectRespostaNoveI}
                                onChange={handleRespostaNoveI}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😰</span> 1 - Todo Tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 3 - Uma boa parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 4 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 5 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="6" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 6 - Nunca
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        {/* Pergunta 10 */}
                        <Box sx={{ mt: 2, pointerEvents: selectRespostaNoveA != null ? 'auto' : 'none', opacity: selectRespostaNoveA != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                10- Durante as últimas 4 semanas, quanto de seu tempo a sua saúde física ou problemas emocionais interferiram com as suas atividades sociais (como visitar amigos, parentes, etc)?              </Typography>
                            <RadioGroup
                                value={selectRespostaDez}
                                onChange={handleRespostaDez}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Todo tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 4 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 5 - Nenhuma parte do tempo
                                    </>
                                } />
                            </RadioGroup>
                        </Box>

                        <Box sx={{ mt: 2, pointerEvents: selectRespostaDez != null ? 'auto' : 'none', opacity: selectRespostaDez != null ? 1 : 0.3 }}>
                            <Typography variant="h6">
                                11- O quanto verdadeiro ou falso é cada uma das afirmações para você?
                            </Typography>
                            <Typography>
                                a) Eu costumo adoecer um pouco mais facilmente que as outras pessoas
                            </Typography>
                            <RadioGroup
                                value={selectRespostaOnzeA}
                                onChange={handleRespostaOnzeA}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span>  1 - Definitivamente verdadeiro
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maioria das vezes verdadeiro
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Não sei
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - A maioria das vezes falso
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Definitiva-
                                        mente falso
                                    </>
                                } />
                            </RadioGroup>
                            <Typography>
                                b) Eu sou tão saudável quanto qualquer pessoa que eu conheço              </Typography>
                            <RadioGroup
                                value={selectRespostaOnzeB}
                                onChange={handleRespostaOnzeB}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Todo tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Nenhuma parte do tempo
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                c) Eu acho que a minha saúde vai piorar
                            </Typography>
                            <RadioGroup
                                value={selectRespostaOnzeC}
                                onChange={handleRespostaOnzeC}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 1 - Todo tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 4 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 5 - Nenhuma parte do tempo
                                    </>
                                } />
                            </RadioGroup>

                            <Typography>
                                d) Minha saúde é excelente
                            </Typography>
                            <RadioGroup
                                value={selectRespostaOnzeD}
                                onChange={handleRespostaOnzeD}
                            >
                                <FormControlLabel value="1" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😍</span> 1 - Todo tempo
                                    </>
                                } />
                                <FormControlLabel value="2" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😁</span> 2 - A maior parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="3" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙂</span> 3 - Alguma parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="4" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>🙁</span> 4 - Uma pequena parte do tempo
                                    </>
                                } />
                                <FormControlLabel value="5" control={<Radio required />} label={
                                    <>
                                        <span style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>😩</span> 5 - Nenhuma parte do tempo
                                    </>
                                } />
                            </RadioGroup>
                        </Box>
                        <Box sx={{ mt: 2, mb: 2, justifyContent: 'space-between' }}>
                            <Typography>
                                Gostaria de receber newsletters da Relevium?
                            </Typography>
                            <RadioGroup
                                value={selectedNesletter}
                                onChange={handleSelectNewsletter}
                                row
                            >
                                <FormControlLabel value="1" control={<Radio required />} label="Sim" />
                                <FormControlLabel value="0" control={<Radio required />} label="Não" />
                            </RadioGroup>
                        </Box>
                        <Button type="submit" variant="contained" color="success" disabled={isLoading}>
                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Confirmar'
                            )}
                        </Button>
                    </Box>
                </form>
            </Container>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Informação"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ops, um erro inesperado ocorreu! Por favor, tente novamente mais tarde.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Confirmar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openErrorAplicador}
                onClose={handleErrorAplicadorClose}
                aria-labelledby="openErrorAplicador-title"
                aria-describedby="openErrorAplicador-description"
            >
                <DialogTitle id="openErrorAplicador-title">
                    {"Informação"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="openErrorAplicador-description">
                        Ops, aplicador do formulário nao encontrado, mas você pode utilizar {" "}
                        <Link href="/sf36/1" target="_blank" rel="noopener noreferrer">
                            esse link.
                        </Link>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button href="/sf36/1">Ok, entendi.</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmation}
                aria-labelledby="handleOpenTerms-title"
                aria-describedby="handleOpenTerms-description"

            >
                <DialogTitle id="handleOpenTerms-title">
                    {"Termos de uso e Políticas de Privacidade"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="handleOpenTerms-description">
                        Ao clicar em "Concordar", você estará aceitando nossos{" "}
                        <Link href="https://relevium.com.br/" target="_blank" rel="noopener noreferrer">
                            Termos de Uso
                        </Link>{" "}
                        e nossas{" "}
                        <Link href="https://relevium.com.br/" target="_blank" rel="noopener noreferrer">
                            Políticas de Privacidade
                        </Link>.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmAction}>Concordar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Sf36;
