import { Box, Container, Typography } from "@mui/material";
import ResponsiveAppBar from "../ResponsiveAppBar";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from 'react';
import { resultadoById } from "../../../service/FormularioService";
import { getRelatorioPaginaInicial } from "../../../service/DashboardService";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const [resultado, setResultado] = useState(null);
    const navigate = useNavigate();

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    useEffect(() => {
        const carregarDados = async () => {
            try {
                const retorno = await getRelatorioPaginaInicial();
                if (retorno != null) {
                    var labelsFromApi = []
                    var datasetFromApi = []
                    retorno.forEach(dado => {
                        labelsFromApi.push(`${dado.mes}/${dado.ano}`)
                        datasetFromApi.push(dado.quantidade)
                    });

                    setResultado({
                        labels: labelsFromApi,
                        datasets: [
                            {
                                label: "Quantitativo de Questionários Respondidos por Mês",
                                data: datasetFromApi,
                                backgroundColor: '#3579F6',
                                borderColor: '#3579F6',
                                borderWidth: 3,
                            },
                        ],

                    })
                }

                console.log(resultado)
            } catch (error) {
                console.error('Erro ao carregar os dados:', error);
                if (error.response.status === 401) {
                    navigate(`/login`)
                }
            }
        };

        carregarDados();
    }, []);

    return (
            <Container>
                <Typography sx={{ marginTop: 2 }} variant="h5">
                    Dashboard
                </Typography>
                <Typography variant="subtitle1">
                    Quantitativo de Questionários Respondidos por Mês
                </Typography>
                {resultado ? (
                    <Line data={resultado} options={options} />
                ) : (
                    <p>Carregando dados do gráfico...</p>
                )}
            </Container>
    );
}

export default Dashboard;