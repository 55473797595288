import {
    Routes,
    Route,
    Link,
    Navigate,
    Outlet,
} from 'react-router-dom';
import ResponsiveAppBar from './adm/ResponsiveAppBar';

const isNotAuthenticated = () => {
    return localStorage.getItem('JWT_TOKEN') == null;
};

export const ProtectedRoute = ({
    redirectPath = '/login',
    children,
}) => {
    if (isNotAuthenticated()) {
        return <Navigate to={redirectPath} replace />;
    }

    return (
        <>
            <ResponsiveAppBar />
            {children ? children : <Outlet />}
        </>
    );
};

