import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Sf36 from './pages/sf36/Sf36';
import Sf36Result from './pages/sf36/Sf36Result';
import Sf36ResultView from './pages/sf36/Sf36ResultView';
import Login from './pages/adm/login/Login';
import PrivateRoute, { ProtectedRoute } from './pages/PrivateRoute';
import Dashboard from './pages/adm/dash/Dashboard';
import Pacientes from './pages/adm/dash/Pacientes';
import PacientesDetalhes from './pages/adm/dash/PacientesDetalhes';
import Aplicadores from './pages/adm/dash/Aplicadores';
import Users from './pages/adm/dash/Users';

function App() {

  return (
    <Routes>
      <Route path="/sf36/:id" element={<Sf36 />} />
      <Route path="/sf36/result/:id" element={<Sf36Result />} />
      <Route path="/sf36/result/:id/view" element={<Sf36ResultView />} />
      <Route path="/login" element={<Login />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/pacientes" element={<Pacientes />} />
        <Route path="/paciente/detalhes/:id" element={<PacientesDetalhes />} />
        <Route path="/aplicadores" element={<Aplicadores />} />
        <Route path="/usuarios" element={<Users />} />
      </Route>
    </Routes>
  )
}

export default App;
