

import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Container, Divider, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { getPacienteById, getResultadosSf36ById } from "../../../service/DashboardService";
import { Line } from 'react-chartjs-2';
import { random } from 'lodash'; // Importe a função random do pacote lodash
import { format } from 'date-fns';
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Função para gerar uma cor aleatória em formato hexadecimal
const generateRandomColor = () => {
    const randomColor = `#${Math.floor(random(0, 16777215)).toString(16)}`;
    return randomColor;
};

const PacientesDetalhes = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [paciente, setPaciente] = React.useState(null);
    const [resultadoGraficoSF36, setResultadoGraficoSf36] = React.useState(null);
    const [resultadoTabela, setResultadoTabela] = React.useState(null);

    const { id } = useParams();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        async function findPacienteById(idPaciente) {
            try {
                const retorno = await getPacienteById(idPaciente);
                if (retorno != null) {
                    setPaciente(retorno);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    navigate(`/login`)
                }
            }
        }

        async function findResultadosSf36ById(idPaciente) {
            try {
                const resultado = await getResultadosSf36ById(idPaciente);
                if (resultado != null) {
                    var data = {
                        labels: [
                            'Capacidade funcional',
                            'Limitação por aspectos físicos',
                            'Dor',
                            'Estado geral de saúde',
                            'Vitalidade',
                            'Aspectos sociais',
                            'Aspectos emocionais',
                            'Saúde mental',
                        ],
                        datasets: [],
                    };


                    resultado.forEach(item => {
                        const color = generateRandomColor()

                        data.datasets.push({
                            label: format(new Date(item.data_cadastro), 'dd/MM/yyyy'),
                            data: [
                                item.result_capacidade_funcional,
                                item.result_limitacao_fisica,
                                item.result_dor,
                                item.result_estado_geral_saude,
                                item.result_vitalidade,
                                item.result_aspectos_sociais,
                                item.result_limitacao_emocional,
                                item.result_saude_mental,
                            ],
                            backgroundColor: color,
                            borderColor: color,
                            borderWidth: 3,
                        },)
                    });
                    setResultadoGraficoSf36(data);
                    setResultadoTabela(resultado)
                }
            } catch (error) {
                console.log(error);
                if (error.response.status === 401) {
                    navigate(`/login`)
                }
            }
        }

        findPacienteById(id)
        findResultadosSf36ById(id)
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
    };

    return (
        <Container>
            {paciente ? (
                <Box>
                    <Typography sx={{ mt: 2 }} variant="h5">
                        Paciente
                    </Typography>

                    <Typography variant="subtitle1">
                        Gerenciar pacientes
                    </Typography>

                    <Divider sx={{ mt: 2 }} />

                    <Box sx={{ mt: 2 }}>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body" fontWeight="bold" sx={{ mr: 1 }}>
                                Nome:
                            </Typography>
                            <Typography variant="body">
                                {paciente.nome}
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body" fontWeight="bold" sx={{ mr: 1 }}>
                                E-mail:
                            </Typography>
                            <Typography variant="body">
                                {paciente.email}
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body" fontWeight="bold" sx={{ mr: 1 }}>
                                Telefone:
                            </Typography>

                            <Typography variant="body">
                                {paciente.telefone}
                            </Typography>
                        </Box>

                        <Divider sx={{ mt: 2 }} />

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6" sx={{ mr: 1 }}>
                                Resultado formulário SF36
                            </Typography>

                            <Box sx={{ borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Gráfico" {...a11yProps(0)} />
                                    <Tab label="Tabela" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                {resultadoGraficoSF36 ? (
                                    <Line data={resultadoGraficoSF36} options={options} />
                                ) : (
                                    <p>Carregando dados do gráfico...</p>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {resultadoTabela ? (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Data</TableCell>
                                                    <TableCell>Capacidade Funcional</TableCell>
                                                    <TableCell>Limitação por aspectos físicos </TableCell>
                                                    <TableCell>Dor</TableCell>
                                                    <TableCell>Estado geral de saúde</TableCell>
                                                    <TableCell>Vitalidade</TableCell>
                                                    <TableCell>Aspectos sociais</TableCell>
                                                    <TableCell>Aspectos emocionais</TableCell>
                                                    <TableCell>Saúde mental</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {resultadoTabela.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Moment format="DD/MM/YYYY">{row.data_cadastro}</Moment>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_capacidade_funcional}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_limitacao_fisica}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_dor}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_estado_geral_saude}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_vitalidade}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_aspectos_sociais}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_limitacao_emocional}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.result_saude_mental}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                ) : (
                                    <p>Carregando dados da tabela...</p>
                                )}
                            </TabPanel>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="70vh" // Ajuste a altura de acordo com a necessidade
                >
                    <CircularProgress />
                </Box>
            )
            }
        </Container >
    )
}

export default PacientesDetalhes;
