import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, Navigate } from 'react-router-dom';
import { getProfile } from '../../service/DashboardService';
import { useNavigate } from "react-router-dom";

function ResponsiveAppBar() {
    const [profileAccess, setProfileAccess] = React.useState();
    const navigate = useNavigate();

    const removeUserSession = () => {
        localStorage.removeItem('JWT_TOKEN');
    };

    const perfilAcesso = async () => {
        try {
            const retorno = await getProfile();
            if (retorno != null && retorno.id_perfil_acesso != null) {
                setProfileAccess(retorno.id_perfil_acesso)
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
            if (error.response.status === 401) {
                navigate(`/login`)
            }
        }
    };

    React.useEffect(() => {
        perfilAcesso()
    }, []);

    return (
        <AppBar position="static" sx={{ backgroundColor: '#31515F' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <img src="/logo.png" alt="Logo" style={{ height: 40, marginRight: 20 }} />

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                        {profileAccess === 1 ? (
                            <>
                                <Button
                                    component={Link}
                                    to="/dashboard"
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Dashboard
                                </Button>

                                <Button
                                    component={Link}
                                    to="/pacientes"
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Pacientes
                                </Button>

                                <Button
                                    component={Link}
                                    to="/aplicadores"
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Aplicadores
                                </Button>

                                <Button
                                    component={Link}
                                    to="/usuarios"
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Usuários
                                </Button>
                            </>
                        ) : (
                            <>

                                <Button
                                    component={Link}
                                    to="/dashboard"
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Dashboard
                                </Button>

                                <Button
                                    component={Link}
                                    to="/pacientes"
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Pacientes
                                </Button>
                            </>
                        )}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Button component={Link}
                            to="/login"
                            onClick={removeUserSession} sx={{ my: 2, color: 'white', display: 'block' }}>
                            Sair
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;