import { Box, Button, Chip, CircularProgress, Container, Divider, Icon, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { getPacientes, getPacientesByName } from "../../../service/DashboardService";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Moment from "react-moment";
import { SearchSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Pacientes = () => {
    const navigate = useNavigate();
    const [resultado, setResultado] = useState();
    const [nomePaciente, setNomePaciente] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');


    const handleChange = (event) => {
        setNomePaciente(event.target.value);
    };

    const handleDataInicial = (event) => {
        console.log(event.target.value)
        setDataInicial(event.target.value);
    };

    const handleDataFinal = (event) => {
        console.log(event.target.value)
        setDataFinal(event.target.value);
    };

    const abrirDetalhes = (row) => {
        navigate(`/paciente/detalhes/${row.id}`)
    };

    const onClickPesquisarPorNome = () => {
        const carregarDados = async () => {
            try {
                setResultado()
                const retorno = await getPacientesByName(nomePaciente, dataInicial, dataFinal);
                if (retorno != null) {
                    setResultado(retorno)
                }
            } catch (error) {
                console.error('Erro ao carregar os dados:', error);
                if (error.response.status === 401) {
                    navigate(`/login`)
                }
            }
        };
        carregarDados();
    }

    useEffect(() => {
        const carregarDados = async () => {
            try {
                const retorno = await getPacientes();
                if (retorno != null) {
                    setResultado(retorno)
                }
            } catch (error) {
                console.error('Erro ao carregar os dados:', error);
            }
        };
        carregarDados();
    }, []);

    return (
        <Container>
            <Typography sx={{ mt: 2 }} variant="h5">
                Pacientes
            </Typography>
            <Typography variant="subtitle1">
                Gerenciar pacientes
            </Typography>
            <Divider sx={{ mt: 2 }} />

            <Box sx={{ mt: 2 }}>
                <TextField
                    sx={{ width: "40em", mr: 2 }}
                    variant="outlined"
                    label="Nome do paciente"
                    value={nomePaciente}
                    inputProps={{ maxLength: 100 }}
                    onChange={handleChange} />
{/* 
                <label style={{ height: "30em" }} for="data_inicial">Filtrar de:</label>
                <input value={dataInicial}
                    onChange={handleDataInicial}
                    type="date" id="inicio" name="data_inicial" />

                <label for="birthddata_final">à:</label>
                <input value={dataFinal}
                    onChange={handleDataFinal}
                    type="date" id="fim" name="data_final" /> */}

                <Button
                    sx={{ ml: 2, mt: 1 }}
                    variant="contained"
                    color="success"
                    onClick={onClickPesquisarPorNome}
                >Pesquisar</Button>
            </Box>

            {
                resultado ? (
                    <div style={{ height: 400, width: '100%' }}>
                        <TableContainer sx={{ mt: 4 }} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Desde</TableCell>
                                        <TableCell>Aplicador</TableCell>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>E-mail</TableCell>
                                        <TableCell>Telefone</TableCell>
                                        <TableCell>Nascimento</TableCell>
                                        <TableCell>Cidade</TableCell>
                                        <TableCell>Estado</TableCell>
                                        <TableCell>Tempo médio (min)</TableCell>
                                        <TableCell>E-mail validado?</TableCell>
                                        <TableCell>Receber newsletter?</TableCell>
                                        <TableCell>Opções</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resultado.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <Moment format="DD/MM/YYYY">{row.data_cadastro}</Moment>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.nome_aplicador}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.nome}
                                            </TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.telefone}</TableCell>
                                            <TableCell>
                                                <Moment format="DD/MM/YYYY">{row.nascimento}</Moment></TableCell>
                                            <TableCell>{row.cidade}</TableCell>
                                            <TableCell>{row.estado}</TableCell>
                                            <TableCell>{Number(row.tempo_medio_preenchimento).toFixed(2)}</TableCell>
                                            <TableCell>{row.confirmou_email === 1 ? <Chip label="Sim" color="success" />
                                                : <Chip label="Não" color="error" />
                                            }</TableCell>
                                            <TableCell>{row.receber_news === 1 ? <Chip label="Sim" color="success" />
                                                : <Chip label="Não" color="error" />
                                            }</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => abrirDetalhes(row)} >
                                                    <SearchSharp />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="70vh" // Ajuste a altura de acordo com a necessidade
                    >
                        <CircularProgress />
                    </Box>
                )
            }

        </Container >
    );
}

export default Pacientes;