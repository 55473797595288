import axios from 'axios';
import { URL_API } from './UrlApi';

export const getRelatorioPaginaInicial = async () => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/paciente/relatorio/inicial`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        console.log('Relatorio retornado com sucesso!', response.data);
        return response.data;
    })
        .catch(error => {
            console.error('Erro ao receber os dados:', error);
            throw error;
        });
};

export const getPacientes = async () => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/paciente`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        console.log('Relatorio retornado com sucesso!', response.data);
        return response.data;
    })
        .catch(error => {
            console.error('Erro ao receber os dados:', error);
            throw error;
        });
};

export const getPacientesByName = async (nome, data_inicio, data_fim) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/paciente?nome=${nome}&data_inicio=${data_inicio}&data_fim=${data_fim}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        console.log('Paciente retornado com sucesso!', response.data);
        return response.data;
    })
        .catch(error => {
            console.error('Erro ao receber os dados:', error);
            throw error;
        });
};

export const getPacienteById = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/paciente/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        console.log('Paciente retornado com sucesso!', response.data);
        return response.data;
    })
        .catch(error => {
            console.error('Erro ao receber os dados:', error);
            throw error;
        });
};

export const getResultadosSf36ById = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/forms-result/result-sf36/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        console.log('Paciente retornado com sucesso!', response.data);
        return response.data;
    })
        .catch(error => {
            console.error('Erro ao receber os dados:', error);
            throw error;
        });
};


export const getAplicadores = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/aplicador`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            console.log('Paciente retornado com sucesso!', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Erro ao receber os dados:', error);
            throw error;
        });
};

export const enviarAplicadores = async (dadosAplicadores) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.post(`${URL_API}/aplicador`,
        dadosAplicadores,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },

    ).then(response => {
        console.log('Aplicador salvo com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    });
};

export const ativaAplicador = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.put(`${URL_API}/aplicador/ativa/${id}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Aplicador alterado com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao alterar os dados:', error);
        throw error;
    });
};

export const desativaAplicador = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.put(`${URL_API}/aplicador/desativa/${id}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Aplicador alterado com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao alterar os dados:', error);
        throw error;
    });
};

export const getAllPerfilAcesso = async () => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/nivel-acesso`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Buscar perfil de acesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao buscar perfil de acesso:', error);
        throw error;
    });
};

export const enviarUsuario = async (dadosUsuario) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.post(`${URL_API}/user`,
        dadosUsuario,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },

    ).then(response => {
        console.log('user salvo com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao salvar os usuario:', error);
        throw error;
    });
};

export const getAllUsers = async () => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/user`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Buscar usuarios!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao buscar usuarios:', error);
        throw error;
    });
};

export const desativaUsuario = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.put(`${URL_API}/user/desativa/${id}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Usuario alterado com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao alterar os dados:', error);
        throw error;
    });
};

export const ativaUsuario = async (id) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.put(`${URL_API}/user/ativa/${id}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Usuario alterado com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao alterar os dados:', error);
        throw error;
    });
};

export const getProfile = async () => {
    const token = localStorage.getItem('JWT_TOKEN');
    return await axios.get(`${URL_API}/auth/profile`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        },
    ).then(response => {
        console.log('Usuario retornado com sucesso!', response.data);
        return response.data;
    }).catch(error => {
        console.error('Erro ao retornar os dados:', error);
        throw error;
    });
};