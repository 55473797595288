import { AppBar, Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { resultadoById, viewEmail } from "../../service/FormularioService";
import { format } from "date-fns";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Sf36ResultView = () => {
    const [resultado, setResultado] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                const retorno = await resultadoById(id);
                if (retorno != null) {
                    setResultado(
                        {
                            labels: [
                                'Capacidade funcional',
                                'Limitação por aspectos físicos',
                                'Dor',
                                'Estado geral de saúde',
                                'Vitalidade',
                                'Aspectos sociais',
                                'Aspectos emocionais',
                                'Saúde mental',
                            ],
                            datasets: [
                                {
                                    label: format(new Date(retorno.data_cadastro), 'dd/MM/yyyy'),
                                    data: [
                                        retorno.result_capacidade_funcional,
                                        retorno.result_limitacao_fisica,
                                        retorno.result_dor,
                                        retorno.result_estado_geral_saude,
                                        retorno.result_vitalidade,
                                        retorno.result_aspectos_sociais,
                                        retorno.result_limitacao_emocional,
                                        retorno.result_saude_mental,
                                    ],
                                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                                    borderColor: 'rgba(75, 192, 192, 1)',
                                    borderWidth: 3,
                                },
                            ],
                        }
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }

        async function viewedEmail() {
            try {
                await viewEmail(id);
            } catch (error) {
                console.log(error);
            }
        }

        async function fetchDataAndEmail() {
            await Promise.all([fetchData(), viewedEmail()]);
        }

        fetchDataAndEmail();
    }, []);

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return (
        <div>
            <AppBar sx={{ backgroundColor: '#31515F', height: 200, position: 'static' }}>
                <Container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Box>
                        <img src="/logo.png" alt="Logo" style={{ height: 80 }} />
                        <Typography
                            variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Questionário Qualidade de Vida
                        </Typography>
                        <Typography variant="body1">
                            Resultado do formulário
                        </Typography>
                    </Box>
                </Container>
            </AppBar>
            <Box sx={{
                mt: 2, display: 'flex', alignItems: 'center', height: '100%', flexDirection: 'column'
            }}>
                <Typography variant="h4">
                    Resultado do formulário
                </Typography>
                <Typography>
                    O valor das questões em notas de 8 domínios que variam de 0 (zero) a 100 (cem), onde 0 = pior e 100 = melhor para cada domínio.
                </Typography>
                <Box sx={{ height: "100vh", width: "100vh" }}>
                    {resultado ? <Line data={resultado} options={options} />
                        : <p>Carregando...</p>}
                </Box>
            </Box>

        </div>
    )
}

export default Sf36ResultView;
