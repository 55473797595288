import { useEffect, useState } from 'react';
import { Box, Button, Container, IconButton, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, CircularProgress, Alert } from "@mui/material"
import Moment from 'react-moment';
import InputMask from "react-input-mask";
import { ativaAplicador, desativaAplicador, enviarAplicadores, getAplicadores } from '../../../service/DashboardService';
import { Add, Remove } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const Aplicadores = () => {
    const [listAplicadores, setListaAplicadores] = useState(null);
    const [nome, setNome] = useState("");
    const [endereco, setEndereco] = useState("");
    const [telefone, setTelefone] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const navigate = useNavigate();

    const handleNome = (event) => {
        setNome(event.target.value);
    };

    const handleEndereco = (event) => {
        setEndereco(event.target.value);
    };

    const handleTelefone = (event) => {
        setTelefone(event.target.value);
    };

    const carregarDados = async () => {
        try {
            const retorno = await getAplicadores();
            if (retorno != null) {
                setListaAplicadores(retorno)
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
            if (error.response.status === 401) {
                navigate(`/login`)
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            setIsLoading(true)
            const dadosAplicadores = {
                nome: nome,
                endereco: endereco,
                telefone: telefone,
                desativado: 0
            }
            const dados = await enviarAplicadores(dadosAplicadores)
            if (dados != null) {
                setIsSaved(true)
                setNome("")
                setEndereco("")
                setTelefone("")
                carregarDados()
            }
            setIsLoading(false)
        }
    };

    useEffect(() => {
        carregarDados();
    });

    const desativarAplicador = async (row) => {
        try {
            const retorno = await desativaAplicador(row.id);
            if (retorno != null) {
                carregarDados()
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
        }
    };

    const ativarAplicador = async (row) => {
        try {
            const retorno = await ativaAplicador(row.id);
            if (retorno != null) {
                carregarDados()
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
        }
    };

    return (
        <Container>
            <Typography sx={{ marginTop: 2 }} variant="h5">
                Aplicadores
            </Typography>
            <Typography variant="subtitle1">
                Gerenciar aplicadores questionario
            </Typography>
            <form onSubmit={handleSubmit}>

                {isSaved && (
                    <Alert severity="success">Aplicador salvo com sucesso.</Alert>
                )}

                <Box sx={{ mt: 2 }}>
                    <TextField
                        sx={{ width: "40em" }}
                        variant="outlined"
                        label="Nome do aplicador"
                        value={nome}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleNome}
                        required />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        sx={{ width: "40em" }}
                        variant="outlined"
                        label="Endereço"
                        value={endereco}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleEndereco} required />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <InputMask
                        mask="(99) 9999-9999"
                        value={telefone}
                        onChange={handleTelefone}
                        maskChar=" "
                        required
                    >
                        {() => <TextField style={{ width: "40em" }}
                            label="Telefone"
                            required
                            variant="outlined" />}
                    </InputMask>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Button type="submit" variant="contained" color="success" disabled={isLoading}>
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Confirmar'
                        )}
                    </Button>
                </Box>
            </form>

            {listAplicadores ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Endereço</TableCell>
                                <TableCell>Telefone</TableCell>
                                <TableCell>Desde</TableCell>
                                <TableCell>Inativar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listAplicadores.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.nome}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.endereco}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.telefone}
                                    </TableCell>
                                    <TableCell>
                                        <Moment format="DD/MM/YYYY">{row.data_cadastro}</Moment>
                                    </TableCell>
                                    <TableCell>

                                        {row.desativado === 1 ? (
                                            <Tooltip title="Ativar" arrow disableInteractive>
                                                <span>
                                                    <IconButton onClick={() => ativarAplicador(row)} >
                                                        <Add />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Desativar" arrow disableInteractive>
                                                <span>
                                                    <IconButton onClick={() => desativarAplicador(row)} >
                                                        < Remove />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}


                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <p></p>
            )}
        </Container>
    )
}

export default Aplicadores
