import React, { useState } from 'react';
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Alert,
} from '@mui/material';
import { doLogin } from '../../../service/LoginService';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [exibirAlerta, setExibirAlerta] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (e.target.checkValidity()) {
            const login = await doLogin(username, password)
            if (login == false) {
                console.log("erro ao logar")
                setExibirAlerta(true)
            } else {
                setExibirAlerta(false)
                localStorage.setItem('JWT_TOKEN', login.access_token);
                navigate("/dashboard");
            }
        }

    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Container maxWidth="sm">
                <Paper style={{ padding: '16px' }} elevation={3}>
                    <Container style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} elevation={3}>
                        <img src="https://relevium.com.br/wp-content/uploads/2015/07/LOGO-RELEVIUM-COR.png" alt="Logo Relevium" style={{
                            height: 80,
                        }} />
                    </Container>
                    <Typography variant="h5" align="center" gutterBottom>
                        Login - Sistema Formulários - Relevium
                    </Typography>
                    <form
                        style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                        onSubmit={handleSubmit}
                    >

                        {exibirAlerta && (
                            <Alert severity="warning">Usuário e/ou senha incorretos.</Alert>
                        )}

                        <TextField
                            label="Nome de usuário"
                            type="text"
                            inputProps={{ maxLength: 30 }}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <TextField
                            label="Senha"
                            type="password"
                            inputProps={{ maxLength: 30 }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

                        <Button variant="contained" color="primary" type="submit">
                            Login
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
};

export default Login;