import { useEffect, useState } from 'react';
import { Box, Button, Container, IconButton, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Select, MenuItem, CircularProgress, Alert } from "@mui/material"
import { ativaUsuario, desativaUsuario, enviarUsuario, getAllPerfilAcesso, getAllUsers } from '../../../service/DashboardService';
import { Add, Remove } from '@mui/icons-material';
import { getAllAplicadores } from '../../../service/FormularioService';
import { useNavigate } from "react-router-dom";

const Users = () => {
    const navigate = useNavigate();

    const [list, setLista] = useState(null);
    const [usuario, setUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    const [senhaIguais, setSenhaIguais] = useState(true);

    const [aplicador, setAplicador] = useState(0);
    const [listaAplicador, setListaAplicador] = useState([]);
    const [perfilAcesso, setPerfilAcesso] = useState(0);
    const [listaPerfilAcesso, setListaPerfilAcesso] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const handleUsuario = (event) => {
        setUsuario(event.target.value);
    };

    const handleSenha = (event) => {
        setSenha(event.target.value);
    };

    const handleConfirmarSenha = (event) => {
        setConfirmarSenha(event.target.value);
        setSenhaIguais(event.target.value === senha);
    };

    const handlePerfilAcesso = (event) => {
        setPerfilAcesso(event.target.value);
    };

    const handleChangeAplicador = (event) => {
        setAplicador(event.target.value);
    };

    const carregarDados = async () => {
        try {
            const retorno = await getAllUsers();
            if (retorno != null) {
                setLista(retorno)
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
            navigate(`/login`)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity() && senhaIguais) {
            setIsLoading(true)
            const dadosUsuario = {
                usuario: usuario,
                senha: senha,
                id_perfil_acesso: perfilAcesso,
                id_aplicador: aplicador,
                desativado: 0
            }

            const dados = await enviarUsuario(dadosUsuario)
            if (dados != null) {
                setIsSaved(true)
                setUsuario("")
                setSenha("")
                setConfirmarSenha("")
                setPerfilAcesso(0)
                setAplicador(0)
                carregarDados()
            }
        }
        setIsLoading(false)
    };

    async function getAplicadores() {
        try {
            const retorno = await getAllAplicadores();
            if (retorno != null) {
                setListaAplicador(retorno);
            }
        } catch (error) {
            console.log(error);
            navigate(`/login`);
        }
    }

    async function getAllPerfils() {
        try {
            const retorno = await getAllPerfilAcesso();
            if (retorno != null) {
                setListaPerfilAcesso(retorno);
            }
        } catch (error) {
            console.log(error);
            navigate(`/login`)
        }
    }

    const ativarUsuario = async (row) => {
        try {
            const retorno = await ativaUsuario(row.id);
            if (retorno != null) {
                carregarDados()
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
            navigate(`/login`)
        }
    };

    const desativarUsuario = async (row) => {
        try {
            const retorno = await desativaUsuario(row.id);
            if (retorno != null) {
                carregarDados()
            }
        } catch (error) {
            console.error('Erro ao carregar os dados:', error);
            navigate(`/login`)
        }
    };

    useEffect(() => {
        getAplicadores()
        getAllPerfils()
        carregarDados();
    }, []);

    return (
        <Container>
            <Typography sx={{ marginTop: 2 }} variant="h5">
                Usuários
            </Typography>
            <Typography variant="subtitle1">
                Gerenciar usuários
            </Typography>
            <form onSubmit={handleSubmit}>
                {isSaved && (
                    <Alert severity="success">Usuário salvo com sucesso.</Alert>
                )}
                <Box sx={{ mt: 2 }}>
                    <TextField
                        sx={{ width: "40em" }}
                        variant="outlined"
                        label="Usuário"
                        value={usuario}
                        inputProps={{ maxLength: 20 }}
                        onChange={handleUsuario}
                        required />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        sx={{ width: "40em" }}
                        variant="outlined"
                        label="Senha"
                        type="password"
                        value={senha}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleSenha} required />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <TextField
                        sx={{ width: "40em" }}
                        variant="outlined"
                        type="password"
                        label="Confirmar senha"
                        value={confirmarSenha}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleConfirmarSenha} required />
                </Box>

                {senhaIguais ? (
                    <div></div>) : (
                    <div>Senhas não são iguais</div>
                )}

                <Box sx={{ mt: 2 }}>
                    <Select
                        labelId="id-aplicador"
                        id="id-aplicador"
                        value={aplicador}
                        sx={{ width: "40em" }}
                        label="Aplicador"
                        onChange={handleChangeAplicador}
                        required
                    >
                        <MenuItem value="0">
                            Selecione o aplicador
                        </MenuItem>
                        {listaAplicador.map((aplicador) => (
                            <MenuItem key={aplicador.id} value={aplicador.id}>
                                {aplicador.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Select
                        labelId="id-perfil-acesso"
                        id="id-perfil-acesso"
                        value={perfilAcesso}
                        sx={{ width: "40em" }}
                        label="Perfil Acesso"
                        onChange={handlePerfilAcesso}
                        required
                    >
                        <MenuItem value="0">
                            Selecione o perfil de acesso
                        </MenuItem>
                        {listaPerfilAcesso.map((aplicador) => (
                            <MenuItem key={aplicador.id} value={aplicador.id}>
                                {aplicador.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Button type="submit" variant="contained" color="success" disabled={isLoading}>
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Confirmar'
                        )}
                    </Button>
                </Box>
            </form>

            {
                list ? (
                    <TableContainer sx={{ mt: 2 }} component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Usuario</TableCell>
                                    <TableCell>Perfil</TableCell>
                                    <TableCell>Aplicador</TableCell>
                                    <TableCell>Inativar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.usuario}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.perfil}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.aplicador}
                                        </TableCell>
                                        <TableCell>
                                            {row.desativado === 1 ? (
                                                <Tooltip title="Ativar" arrow disableInteractive>
                                                    <span>
                                                        <IconButton onClick={() => ativarUsuario(row)} >
                                                            <Add />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Desativar" arrow disableInteractive>
                                                    <span>
                                                        <IconButton onClick={() => desativarUsuario(row)} >
                                                            < Remove />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            )}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <p></p>
                )
            }
        </Container >
    )
}

export default Users
