import axios from 'axios';
import { URL_API } from './UrlApi';

export const doLogin = async (username, password) => {
    const usuario = {
        username,
        password
    }
    return await axios.post(`${URL_API}/auth/login`, usuario)
        .then(response => {
            console.log('Login realizado com sucesso!', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Erro ao enviar os dados:', error);
            return false;
        });
};
